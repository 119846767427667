<template>
  <div class="testVideo" id="testVideo">
    <div class="operateBox" v-if="!videoType">
      <ul class="studyMain" id="studyMain">
        <p class="studyTitle">
          学习项目名称
        </p>
        <li v-for="item in 10" :key="item">
          <div>学习内容{{item}}</div>
          <div>未完成</div>
          <div @click="clickLook">查看</div>
        </li>
      </ul>

      <template v-if="infoType">
        <ul class="infoMain">
          <li @click="clickVideo">视频</li>
          <li>图文</li>
          <li>操作</li>
        </ul>
      </template>
    </div>

    <template v-if="videoType">
      <iframe src="static/test.html" frameborder="0" style="width:100%;height:100%"></iframe>
    </template>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  data() {
    return {
      videoType: false,
      infoType: false,
      iframeData: null,
    }
  },
  methods: {
    domCss() {
      var bodyWidth = document.body.clientWidth;
      var bodyHeight = document.body.clientHeight;
      $("#testVideo").css({ width: bodyHeight });
      $("#testVideo").css({ height: bodyWidth });
      $("#studyMain").css({ height: bodyWidth });
    },
    // 点击左侧查看
    clickLook() {
      this.infoType = !this.infoType;
    },
    // 点击右侧视频按钮
    clickVideo() {
      this.videoType = true;
      this.infoType = false;
    },
    iframeBom() {
      this.videoType = false;
    }
  },
  mounted() {
    this.domCss();
    window["iframeBom"] = () => {
      this.iframeBom();
    };
  },
}
</script>
<style>
.testVideo {
  background: #000;
  transform: rotate(90deg);
  transform-origin: 50vw 50vw;
}
.testVideo .operateBox {
  display: flex;
  justify-content: space-between;
}
.testVideo .studyMain {
  width: 300px;
  overflow-y: auto;
  padding-top: 50px;
}
.testVideo .studyMain .studyTitle {
  width: 300px;
  height: 50px;
  line-height: 50px;
  background: #b3b3b3;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 4px;
  position: fixed;
  top: 0;
  left: 0;
}
.testVideo .studyMain li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  padding: 10px 20px;
  line-height: 25px;
  border: 1px solid #ccc;
}
.testVideo .studyMain li div:first-child {
  max-width: 130px;
}
.testVideo .studyMain li div:last-child {
  color: #409eff;
}
.testVideo .infoMain {
  width: 100px;
  height: 120px;
  background: rgba(255, 255, 255, 0.7);
}
.testVideo .infoMain li {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ccc;
}
</style>